import { loadFireStore } from "../../handlers"

export const updateUser = async (userUID, values) => {
  try {
    const doc: any = await loadFireStore("user", userUID)
    await doc.update(values)
    return "success"
  } catch (error) {
    console.error("error - updateUser", error)
    return error
  }
}
