import { useTheme } from "@harborschool/lighthouse"
import log from "loglevel"
import * as React from "react"
import { useRef } from "react"
import { useVsMap, useVsUpdateByScroll } from "../../../hooks/post-contents"
import { videoScrubberGuide } from "../../../utils"
import { DebugPanel } from "./debug-panel"

export function VideoScrubber({
  children,
  videoMarkersPath,
  videoFramesPath,
  canvasFrames,
  $blockContent,
}) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const ref = useRef(null)
  console.group(
    "%cVideoScrubber",
    "background: hsl(218, 100%, 49%); color: #fff; border-radius: 4px; padding: 2px 8px;"
  )

  log.debug(`VideoScrubber/$blockContent`, $blockContent)
  const vsMap = useVsMap(ref, videoMarkersPath, $blockContent)
  useVsUpdateByScroll({ ...vsMap, $blockContent })
  log.debug(`VideoScrubber/vsMap`, vsMap)

  return (
    <>
      <DebugPanel
        videoFramesPath={videoFramesPath}
        canvasFrames={canvasFrames}
        show={videoScrubberGuide}
      />
      <div ref={ref}>{renderWithAccessibility(children, $blockContent)}</div>
    </>
  )
}

VideoScrubber.defaultProps = {}

function renderWithAccessibility(children, blockContent) {
  if (blockContent) {
    const newChildren: any = []
    newChildren[0] = children[0] && children[0].props.children[0]
    return newChildren
  }
  return children
}
