import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { useDimensions } from "../hooks"

export function Measure({ target = "width", liveMeasure = false }) {
  const theme = useTheme()
  const [ref, { x, y, width, height }]: any = useDimensions({ liveMeasure })

  return (
    <System.Block
      backgroundColor="primary200"
      position="absolute"
      display="flex"
      justifyContent="space-between"
      forwardedRef={ref}
      overrides={{
        Block: {
          zIndex: theme.zIndex.header,
        },
      }}
      {...getStyles({ $target: target })}
    >
      <System.Block
        backgroundColor="primary500"
        {...getPinStyles({ $target: target })}
      />
      <System.LabelSmall
        color="primary500"
        overrides={{
          Block: { fontStyle: "italic" },
        }}
        {...getLabelStyles({ $target: target })}
      >
        {target === "width" && width}
        {target === "height" && height}px
      </System.LabelSmall>
      <System.Block
        backgroundColor="primary500"
        {...getPinStyles({ $target: target })}
      />
    </System.Block>
  )
}

Measure.defaultProps = {}

function getStyles({ $target }) {
  switch ($target) {
    case "width":
      return {
        width: "100%",
        height: "10px",
        top: "-30px",
        left: "0px",
      }
    case "height":
      return {
        width: "10px",
        height: "100%",
        right: "calc(30px - 10px)",
        top: "0px",
        flexDirection: "column",
      }
    default:
      return Object.freeze({})
  }
}

function getPinStyles({ $target }) {
  switch ($target) {
    case "width":
      return {
        width: "3px",
        height: "30px",
      }
    case "height":
      return {
        width: "30px",
        height: "3px",
      }
    default:
      return Object.freeze({})
  }
}

function getLabelStyles({ $target }) {
  switch ($target) {
    case "width":
      return {
        transform: "translateY(-300%)",
      }
    case "height":
      return {
        transform: "translateX(-800%)",
      }
    default:
      return Object.freeze({})
  }
}
