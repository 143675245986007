import { useState, useEffect } from "react"
import { lastStepFastForwarded, SCROLL_FOCUS_POINT } from "../../constants"

export function useCurrentStep(
  ref,
  targetClassName,
  focusPoint,
  onChange: any = newCurrentStep => void 0
) {
  const [currentStep, setCurrentStep] = useState(0)
  let memoCurrentStep

  useEffect(() => {
    const scroller = ref.current.querySelector(`.${targetClassName}`)
    function onScroll() {
      const newCurrentStep = getCurrentStep(scroller, focusPoint)
      if (memoCurrentStep !== newCurrentStep) {
        setCurrentStep(newCurrentStep)
        onChange(newCurrentStep)

        memoCurrentStep = newCurrentStep
      }
    }
    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [ref])
  return [currentStep]
}

function getCurrentStep(scroller, focusPoint) {
  const children = scroller.childNodes
  if (children.length) {
    let prevBottom = children[0].getBoundingClientRect().bottom
    for (let i = 1; i < children.length; i++) {
      const { top, bottom, height } = children[i].getBoundingClientRect()
      const breakpoint = (prevBottom + top) / 2
      if (SCROLL_FOCUS_POINT < breakpoint) {
        return i - 1
      }
      // for last child (step)
      if (i === children.length - 1) {
        const playableAmount = height - lastStepFastForwarded
        const done = SCROLL_FOCUS_POINT - breakpoint > playableAmount
        if (done) return children.length
      }
      prevBottom = bottom
    }
  }

  return children.length - 1
}
