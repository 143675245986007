import { useEffect, useState } from "react"
import { loadFireStore } from "../../handlers"
import { CollectionReference } from "@firebase/firestore-types"

export const useUserList = () => {
  const [list, setList]: any = useState(null)

  useEffect(() => {
    async function update() {
      const collection: CollectionReference = await loadFireStore("user", null)
      collection
        .orderBy("date", "desc")
        .onSnapshot(querySnapshot => setList(querySnapshot))
    }
    update()
  }, [])

  return list
}
