import * as React from "react"

export function Check({ width, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      preserveAspectRatio="none"
      style={{
        display: "block",
      }}
    >
      <path d="M20 6L9 17l-5-5"></path>
    </svg>
  )
}

Check.defaultProps = {
  width: 18,
}
