import { useAtom } from "jotai"
import * as React from "react"
import { useRecoilValue } from "recoil"
import { useCanvasFrames } from "../../../hooks/post-contents"
import { userState, veInfoState, veState } from "../../../store"
import { getFrameIndex, getScrollY } from "../../../utils"

export function DebugPanel({ videoFramesPath, canvasFrames, show }) {
  const [user] = useAtom(userState)
  const [loading, unpackProgress] = useCanvasFrames({
    videoFramesPath,
    canvasFrames,
    user,
  })
  const { currentVideoTime, currentProgress } = useRecoilValue(veState)
  const { videoDuration } = useRecoilValue(veInfoState)

  return show ? (
    <div
      style={{
        background: "blue",
        color: "white",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <div
        style={{
          padding: 10,
        }}
      >
        {/* [DebugPanel] */}
        [Info]
        <br />
        videoFramesPath : {videoFramesPath}
        <br />
        videoDuration : {videoDuration}
        <br />
        canvasFrames : {canvasFrames} (FPS : {canvasFrames / videoDuration})
        <br />
        unpackProgress : {unpackProgress}%
        <br />
        <br />
        [Current State]
        <br />y : {getScrollY()}px
        <br />
        currentVideoTime : {currentVideoTime} sec
        <br />
        currentProgress : {currentProgress}%
        <br />
        {/* same logic as CanvasFrameScrubber (canvas-frame-scrubber.ts) */}
        {/* but add + 1, cause there is no image000.jpg */}
        {`currentFrame : image${
          getFrameIndex({
            percentage: currentProgress,
            totalLength: canvasFrames,
          }) + 1
        }.jpg`}
      </div>
    </div>
  ) : (
    <></>
  )
}
