import axios from "axios"
import * as React from "react"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import * as Illust from "../../assets/illust"
import { mailContents, SUBSCRIPTION_TRIAL_DAYS } from "../../constants"
import {
  addUser,
  fetchAndStoreUserDB,
  getUserDocumentByEmail,
  loadAuth,
  mailchimpSubscribe,
  sendMail,
  updateUser,
} from "../../handlers"
import { UserDBT } from "../../types"
import { isClient, isWhiteListed, Log, ONE_DAY } from "../../utils"
import { alertState, loadingState } from "./../../store"
import { userState } from "./../../store/store-jotai"
import { useAtom } from "jotai"
import log from "loglevel"

export const useUser = () => {
  const [user, setUser] = useAtom(userState)
  const [, setLoading] = useAtom(loadingState)
  const setAlert = useSetRecoilState(alertState)

  useEffect(() => {
    async function update() {
      let auth = await loadAuth()
      auth.onAuthStateChanged(async function (firebaseUser) {
        console.group(
          "%c useUser - auth",
          "background: hsl(218, 100%, 49%); color: #fff; border-radius: 4px; padding: 2px 8px 2px 0px;"
        )

        /**
         * Main Logic
         */
        const isSignIn =
          isClient && auth.isSignInWithEmailLink(window.location.href)
        log.debug(`signIn-fix/onAuthStateChanged!`)

        // get email
        let email
        getEmail()

        if (!firebaseUser && isSignIn) await startSignIn()
        else if (firebaseUser) await completeSignIn()
        else setLoading("loaded") // Set loading state [before -> loaded], if there is nothing to do.

        /**
         * Handlers
         */
        function getEmail() {
          if (firebaseUser) email = firebaseUser.email
          else if (isSignIn) {
            email = window.localStorage.getItem("emailForSignIn")
            if (!email)
              email = window.prompt(
                "Please provide your email for confirmation"
              )
          }
        }
        async function startSignIn() {
          try {
            // Firebase auth sign-in start
            log.debug(`signIn-fix/FIREBASE AUTH SIGN-IN START! 🙋‍♀️`)

            // show loading only firebase auth is working
            setLoading("loading")

            const signInResult = await auth.signInWithEmailLink(
              email,
              window.location.href
            )
          } catch (error) {
            console.error(`signInWithEmailLink/error`, error)
            setAlert({
              show: true,
              kind: "negative",
              title: "Sign in Failed",
              message: error.message,
              body: <Illust.Hammer width={100} />,
            })

            setLoading("loaded")
          }
        }
        async function completeSignIn() {
          // add new user to DB (sign up) & set DB as store
          const isNewUser =
            firebaseUser?.metadata.creationTime ===
            firebaseUser?.metadata.lastSignInTime

          if (isSignIn && isNewUser) {
            const userDB: UserDBT = await getUserDocumentByEmail(email)
            // [Case 1] New User Sign Up
            if (!userDB) await handleNewUserSignup()
            // [Case 1-2] Sign Up after checkout
            else await handleSignupAfterCheckout({ userDB })
          }
          // [Case 2] Existing User Sign In
          else if (isSignIn && !isNewUser) await handleExistingUserSignin()
          // [Case 3] Signed in User Page View
          else await handleSignedUserPageview()

          setLoading("loaded")
          log.debug(`signIn-fix/LOADED!`)
          log.debug(`signIn-fix/FIREBASE AUTH SIGN-IN END! 🙆‍♂️`)
        }

        async function handleNewUserSignup() {
          const uid: any = firebaseUser?.uid
          const defaultTrialPeriods =
            ONE_DAY * (isWhiteListed(email) ? 9999 : SUBSCRIPTION_TRIAL_DAYS)
          const userBasicInfo = await addUser({
            email,
            uid,
            trial_end: Math.floor(Date.now() / 1000) + defaultTrialPeriods,
          })
          const userData = {
            uid,
            trialLeft: defaultTrialPeriods,
            ...userBasicInfo,
          }
          setUser(userData)
          log.debug(`signIn-fix/setUser (Case 1)`)
          window.localStorage.removeItem("emailForSignIn")

          await newUserMailing({ userName: userData.name, email })

          setAlert({
            show: true,
            kind: "positive",
            title: "Welcome",
            message: `Signed up with ${email}`,
            body: <Illust.Done width={100} />,
          })
        }
        async function handleSignupAfterCheckout({ userDB }) {
          Log.info("[Case 1-2]")
          await newUserMailing({ userName: userDB.name, email })
          // Only fetch user info once, if there is no user state
          if (!user) await fetchAndStoreUserDB({ userDB, email, user, setUser })

          setAlert({
            show: true,
            kind: "positive",
            title: "Welcome",
            message: `Signed up with ${email}`,
            body: <Illust.Done width={100} />,
          })
          log.debug(`signIn-fix/setUser (Case 1-2)`)
        }

        async function handleExistingUserSignin() {
          log.debug(`signIn-fix/START! (Case 2)`)
          if (!user) await fetchAndStoreUserDB({ email, user, setUser })
          log.debug(`signIn-fix/COMPlETE! (Case 2)`)

          if (isSignIn) {
            setAlert({
              show: true,
              kind: "positive",
              title: "Done",
              message: `Signed with ${email}`,
              body: <Illust.Done width={100} />,
            })
          }
        }
        async function handleSignedUserPageview() {
          log.debug(`signIn-fix/setUser (Case 3)`)
          if (!user) await fetchAndStoreUserDB({ email, user, setUser })
          else {
            const uid: any = firebaseUser?.uid
            await updateUser(uid, {
              last_active: {
                date: Date.now(),
                page: window?.location.pathname,
              },
            })
          }
        }

        console.groupEnd()
      })
    }
    update()
  }, [])
}

async function newUserMailing({ userName, email }) {
  const mailchimpRes = await mailchimpSubscribe({ email })

  sendMail({
    subject: "Welcome to Harbor School - let me know if I can help",
    emails: [email],
    htmlString: mailContents.welcome.body.replace("[userName]", userName),
  })
}
