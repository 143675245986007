import { loadFireStore } from "../../handlers"
import { DocumentReference } from "@firebase/firestore-types"

export const addUser = async ({ email, uid = null, ...rest }) => {
  try {
    const basicInfo = {
      email,
      name: email.split("@")[0], // default name is email
      date: new Date().getTime(),
      ...rest,
    }

    let res
    if (uid) {
      const doc: DocumentReference = await loadFireStore("user", uid)
      res = await doc.set({ ...basicInfo })
    } else {
      const collection: any = await loadFireStore("user", null)
      res = await collection.add({ ...basicInfo })
      const result = await res.get()
      uid = result.id
    }

    return { ...basicInfo, uid }
  } catch (error) {
    console.error("error - updateUser", error)
    // alert("Error (add-user)")
    return error
  }
}
