import log from "loglevel"

export const FrameUnpacker = (() => {
  const unpack = async options => {
    const urlPattern = options.urlPattern,
      start = options.start,
      end = options.end,
      padding = options.padding,
      progressHook = options.progress

    const bitmaps: any = []
    const calls: any = []

    const timeStart = performance.now()

    // download each frame image and prep it up
    for (let index = start; index <= end; index++) {
      if (index % (1 / options.videoCanvasQuality) === 0) {
        const id = index.toString().padStart(padding, "0")
        const url = urlPattern.replace("{{id}}", id)

        // if (options.slowLoad) {
        //   const res = await fetch(url)
        //   const blob = await res.blob()
        //   const bitmap = await createImageBitmap(blob)

        //   // update progress
        //   progressHook(Math.ceil((bitmaps.length / end) * 100))
        //   calls.push(bitmaps.push({ id: index, bitmap: bitmap }))
        // } else
        //   calls.push(
        //     fetch(url)
        //       .then(res =>
        //         res.blob().then(blob =>
        //           createImageBitmap(blob).then(bitmap => {
        //             // update progress
        //             progressHook(Math.ceil((bitmaps.length / end) * 100))
        //             return bitmaps.push({ id: index, bitmap: bitmap })
        //           })
        //         )
        //       )
        //       .catch(error => {
        //         console.error("Error:", error)
        //       })
        //   )

        // make image and add url as src inside of calls array
        function loadImage() {
          const image = new Image()
          image.src = url
          progressHook(Math.ceil((bitmaps.length / end) * 100))
          return bitmaps.push({ id: index, bitmap: image })
        }

        calls.push(loadImage())
      }
    }

    // wait for all the downloads to finish... (a more eager implementation that starts putting
    // the scrubbing as soon as the first few frames are downloaded can also be done, but we'll
    // keep thing s simple for now)
    await Promise.all(calls)

    // sort the downloaded frame bitmaps in order, they could have been downloaded haphazardly
    bitmaps.sort((a, b) => {
      return a.id - b.id
    })

    // once that's done, construct an array of just frames that would be returned
    const frames: any = []
    bitmaps.map(bitmap => frames.push(bitmap.bitmap))
    log.debug(
      `%cVideoScrubber/bitmaps.length: ${bitmaps.length}`,
      `color: yellow;`
    )

    const timeDelta = performance.now() - timeStart

    log.debug(
      `%cVideoScrubber/Average extraction time per frame: ${
        timeDelta / (end - start)
      }ms`,
      `color: yellow;`
    )

    return frames
  }

  return {
    unpack,
  }
})()
