import { styled } from "styletron-react"
import { motion } from "framer-motion"
import { SCROLL_FOCUS_POINT, VIDEO_RATIO } from "../../../../constants"

/**
 * SectionDetail
 */
export const Grid = styled("div", ({ $theme }) => {
  return {
    display: "grid",
    gridTemplateColumns: "6fr minmax(0, 6fr)",
    columnGap: $theme.sizing.scale700,
    [$theme.mediaQuery.large]: {
      gridTemplateColumns: "1fr",
    },
  }
})

/**
 * SectionDetailSteps
 */
export const SectionDetailStepsWrap = styled("div", ({ $theme }) => {
  return {
    background: $theme.colors.backgroundSecondary,
    padding: `0px ${$theme.sizing.scale1000}`,
    [$theme.mediaQuery.large]: {
      padding: `0px ${$theme.sizing.scale800}`,
      // restrict <Code> widths on mobile
      width: "100vw",
      boxSizing: "border-box",
    },
  }
})

/**
 * SectionDetailCanvas
 */
export const SectionDetailExplorerWrap = styled("div", ({ $theme }) => {
  let gapBetweenWindow = `(100vw - ${$theme.breakpoints.large}px)/2`

  return {
    width: `calc(100% + ${gapBetweenWindow})`,
    marginLeft: `calc(${gapBetweenWindow} * -1)`,
    [$theme.mediaQuery.large]: {
      position: "sticky",
      top: `calc(100vh - ${VIDEO_RATIO * 100}vw)`,
      zIndex: $theme.zIndex.header,
      width: "100%",
      margin: "0px",
    },
  }
})
export const StickyWrap = styled("div", ({ $theme }) => {
  return {
    position: "sticky",
    top: SCROLL_FOCUS_POINT + "px",
    display: "flex", // to fit video size (height)
    [$theme.mediaQuery.large]: {
      position: "absolute",
      top: "0px",
    },
  }
})
export const FrameUnpackProgress = styled("div", ({ $theme }) => {
  return {
    background: $theme.colors.primary,
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    color: $theme.colors.contentInversePrimary,
    ...$theme.typography.ParagraphMedium,
    fontStyle: "italic",
    fontWeight: 700,
  }
})

/**
 * Step
 */
export const StepWrap = styled(
  "div",
  ({ $theme, $isFirst, $isLast, $videoScrubberGuide, $scrollSpeed }) => {
    return {
      position: $videoScrubberGuide ? "relative" : "initial",
      ...getPaddingStyles({ $theme, $isFirst, $isLast, $scrollSpeed }),
    }
  }
)
export const StepInnerWrap = styled(motion.div, ({ $theme }) => {
  return {
    background: $theme.colors.backgroundPrimary,
    padding: `0px ${$theme.sizing.scale800}`,
  }
})

function getPaddingStyles({ $theme, $isFirst, $isLast, $scrollSpeed }) {
  const expand = 100 / $scrollSpeed || 1

  if ($isFirst) {
    return {
      paddingTop: `calc(${$theme.sizing.scale4800} * ${expand})`,
      paddingBottom: `calc(${$theme.sizing.scale2400} * ${expand})`,
    }
  } else if ($isLast) {
    return {
      paddingTop: `calc(${$theme.sizing.scale4800} * ${expand})`,
      paddingBottom: `calc(${$theme.sizing.scale4800} * ${expand})`,
    }
  }
  return {
    padding: `calc(${$theme.sizing.scale2400} * ${expand}) 0px`,
  }
}

/**
 * StepProgress
 */
export const StepProgressText = styled(motion.span, ({ $theme }) => {
  return {
    position: "absolute",
    width: "100%",
    height: "100%",
    // background: "beige",
    left: "0",
    top: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
})
