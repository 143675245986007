import * as React from "react"
import { useCurrentStep } from "../../../../hooks"
import { useRef } from "react"
import { useTheme } from "@harborschool/lighthouse"
import { SectionDetailStepsWrap } from "./styled-components"
import { SectionDetailStepsContext, userState } from "../../../../store"
import {
  SECTION_DETAIL_STEPS_CLASSNAME,
  FOCUS_POINT,
} from "../../../../constants"
import { SectionDetailContext } from "../../../../store/section-detail-provider"
import { useAtom } from "jotai"

export function SectionDetailSteps({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [user] = useAtom(userState)
  const ref = useRef(null)
  const ctx: any = React.useContext(SectionDetailContext) // States from Form
  const [currentStep] = useCurrentStep(
    ref,
    SECTION_DETAIL_STEPS_CLASSNAME,
    FOCUS_POINT,
    newCurrentStep => {
      ctx.setCurrentStepInfo({
        ...ctx.currentStepInfo,
        current: newCurrentStep,
        progress: (newCurrentStep / children.length) * 100,
      })
    }
  )
  const sectionDetailStepsProps = { $currentStep: currentStep }

  return (
    <SectionDetailStepsContext.Provider value={sectionDetailStepsProps}>
      <SectionDetailStepsWrap ref={ref} {...sharedProps}>
        <div className={SECTION_DETAIL_STEPS_CLASSNAME}>
          {React.Children.map(children, (child, index) => {
            const active = currentStep == index
            const newProps = {
              active,
              isFirst: index === 0,
              isLast: index === children.length - 1,
              scrollSpeed: user?.settings?.scrollSpeed,
            }
            return React.cloneElement(child, { ...newProps })
          })}
        </div>
      </SectionDetailStepsWrap>
    </SectionDetailStepsContext.Provider>
  )
}

SectionDetailSteps.defaultProps = {}
