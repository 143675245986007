import { AlertPropsT } from "@harborschool/lighthouse"
import { atom, RecoilState } from "recoil"
import { MEMBERSHIP } from "./../constants"
import { LoadingT, MembershipT, UserT } from "./../types"

export const alertState: RecoilState<AlertPropsT> = atom({
  key: "alert",
  default: {
    show: false,
    kind: "positive",
    title: "",
    message: "",
    body: null,
  },
})

export const selectState = atom({
  key: "select",
  default: "",
})

/**
 * Post Layout
 */
export const veFramesState = atom({
  key: "veFrames",
  default: {
    loading: false,
    progress: 0,
    frames: [],
  },
})
export const veInfoState = atom({
  key: "veInfo",
  default: {
    videoDuration: 0,
  },
})
export const veState = atom({
  key: "ve",
  default: {
    currentVideoTime: 0,
    currentProgress: 0,
  },
})

export const veSectionState = atom({
  key: "veSection",
  default: {
    currentSection: -1,
  },
})

export const veTOCState = atom({
  key: "veTOC",
  default: {
    show: false,
  },
})

/**
 * Membership
 */
export const membershipState: RecoilState<MembershipT> = atom({
  key: "membership",
  default: MEMBERSHIP.yearly,
})

/**
 * Admin (Temp)
 */
export const constructionState = atom({
  key: "construction",
  default: true,
})
