import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { Link, useIntl } from "gatsby-plugin-intl-with-root"
import { isClient } from "../../../utils"

export function StyledLink({ children, href }) {
  const intl = useIntl()

  return (
    <System.StyledLink
      as="a"
      href={handleHref(href, intl)}
      target="_blank"
      highlight
    >
      {children}
    </System.StyledLink>
  )
}

StyledLink.defaultProps = {}

function handleHref(href, intl) {
  if (href.includes("https://localhost") || href.includes("http://localhost")) {
    const origin = isClient && window.location.origin
    const parts = href.split("/")
    // remove first 4 items on parts ("https:", "", "localhost:[PORT]", "ko")
    parts.shift()
    parts.shift()
    parts.shift()
    parts.shift()

    return `${origin}/${intl.locale}/${parts.join("/")}`
  }
}
