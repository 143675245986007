import axios from "axios"
import * as React from "react"
import * as Illust from "../../assets/illust"
import { STRIPE_SERVERLESS_API_BASE } from "../../constants"
import { loadFireStore } from "../../handlers"
import log from "loglevel"

export const updatePayment = async (
  user,
  stripe,
  setFormLoading,
  setModal,
  setAlert,
  setUser
) => {
  log.debug(`updatePayment/user`, user)
  const { token } = await stripe.createToken({ name: user.email })
  if (token) {
    try {
      setFormLoading(true)
      await axios
        .post(STRIPE_SERVERLESS_API_BASE + "/customers/update", {
          customer: user.customer,
          source: token.id,
        })
        .catch(error => {
          throw Error(error.response.data.message)
        })
      const newStripe = { ...user.customer, token: token.id }
      const newPaymentInfo = {
        card: token.card,
        stripe: newStripe,
      }
      let doc = await loadFireStore("user", user.uid)
      await doc.update(newPaymentInfo)
      setUser({
        ...user,
        ...newPaymentInfo,
      })
      setModal("")
      setAlert({
        show: true,
        kind: "positive",
        title: "Payment Updated",
        message: "",
        body: <Illust.Done width={100} />,
      })
      setFormLoading(false)
    } catch (error) {
      console.error("error", error)
      setModal("")
      setAlert({
        show: true,
        kind: "negative",
        title: "error",
        message: error.message,
        body: <Illust.Hammer width={100} />,
      })
      setFormLoading(false)
    }
  }
}
