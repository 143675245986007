import { useEffect } from "react"
import { loadFireStore } from "../../handlers"
import { loadingState, userState } from "./../../store"
import { useAtom } from "jotai"
import { getCourseAndPostId } from "../../utils"
import log from "loglevel"

export const useLastPost = currentPostPath => {
  const [loading] = useAtom(loadingState)
  const [user] = useAtom(userState)

  useEffect(() => {
    async function update() {
      const doc = await loadFireStore("user", user?.uid)
      const { course, postId } = getCourseAndPostId(currentPostPath)
      const collection = await doc.collection("progress")

      await collection
        .doc(course)
        .update({ last: postId })
        .then(() => {})
        .catch(async error => {
          log.debug(`mark-as-complete/error`, error)
          log.debug(`mark-as-complete/add a new document`)
          await collection.doc(course).set({ last: postId })
        })
    }
    if (loading === "loaded" && user?.uid) update()
  }, [loading, user])
}
