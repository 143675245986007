export const EMAILS = {
  ruucm: `"Yongmin Ruucm" <ruucm@harbor.school>`,
  contact: `contact@harbor.school`,
  notify: `"HARBOR SCHOOL" <notify@harbor.school>`,
  noreply: "no-reply@harbor.school",
}

export const MAILCHIMP_FORM =
  "https://school.us16.list-manage.com/subscribe/post?u=ec7a9066b2c1de4e7f9961426&amp;id=fbc817177b"

export const SES_BASE_URL =
  "https://dnfa18jeh9.execute-api.us-east-1.amazonaws.com/prod"
export const defaultFromEmail = EMAILS.ruucm
export const mailContents = {
  welcome: {
    subject: "Welcome to Harbor School - let me know if I can help",
    body: require("./welcome-template-body").default.replace(
      "text-decoration: underline;",
      "text-decoration: none;"
    ),
  },
  trialEndsEN: {
    subject: "⏳ Your Harbor School free access ends in one day.",
    body: require("./trial-ends-soon-body-en").default.replace(
      "text-decoration: underline;",
      "text-decoration: none;"
    ),
  },
  trialEndsKO: {
    subject: "⏳ 하버스쿨 Free access 가 하루 남았습니다",
    body: require("./trial-ends-soon-body-ko").default.replace(
      "text-decoration: underline;",
      "text-decoration: none;"
    ),
  },
}
