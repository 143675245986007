import * as React from "react"
import Markdown, { compiler } from "markdown-to-jsx"
import * as PostContents from "../templates/post-layout/post-contents"

export function InlineMarkdownText({ children, forceInline = true }) {
  return <>{handleInlineMarkdownTexts(children, forceInline)}</>
}

InlineMarkdownText.defaultProps = {}

function handleInlineMarkdownTexts(md, forceInline) {
  const mdContent = Array.isArray(md) ? md.join("") : md

  const complied =
    typeof mdContent === "string" ? (
      <Markdown
        children={mdContent}
        options={{
          forceInline,
          overrides: {
            a: {
              component: PostContents.StyledLink,
              props: {},
            },
          },
        }}
      />
    ) : (
      mdContent
    )

  return complied
}
