import { isDev } from "../isDev"

export const COURSES = [
  // {
  //   label: "BEGINNER",
  //   title: "Framer: Interactive Design System",
  //   key: "framer-interactive-design-system",
  //   excerpt:
  //     "Let's make an interactive design system with Framer from scratch.",
  //   courseParts: [
  //     {
  //       label: "FORMS",
  //       key: "forms",
  //     },
  //     {
  //       label: "PROGRESS",
  //       key: "progress",
  //     },
  //     {
  //       label: "NAVIGATION",
  //       key: "navigation",
  //     },
  //     {
  //       label: "SURFACES",
  //       key: "surfaces",
  //     },
  //     {
  //       label: "CONTENT ─ ADVANCED",
  //       key: "content-advanced",
  //     },
  //     {
  //       label: "FORMS ─ ADVANCED",
  //       key: "forms-advanced",
  //     },
  //   ],
  //   illust: "",
  //   show: ["course-cards", "post-layout", "learn"],
  // },
  {
    label: "BEGINNER",
    title: "Clone Apple Website using Framer",
    key: "clone-apple-website-framer",
    excerpt:
      "Learn how to clone the Apple website using Framer. This process includes how to draw the graphics needed to create a website in Framer, and how to create world-class scroll interactions in Framer like Apple does.",
    courseParts: [
      {
        label: "Course Introduction",
        key: "course-introduction",
      },
      {
        label: "Apple Website: Graphics",
        key: "apple-website-graphics",
      },
      {
        label: "Framer Basics: Interaction",
        key: "framer-basics-interaction",
      },
      {
        label: "Apple Website: Interaction",
        key: "apple-website-interaction",
      },
      {
        label: "Framer Basics: Responsive Graphic",
        key: "framer-basics-responsive-graphic",
      },
      {
        label: "Apple Website: Responsive Interaction",
        key: "apple-website-responsive-interaction",
      },
    ],
    illust: "",
    show: ["course-cards", "post-layout", "learn"],
  },
  // {
  //   label: "BEGINNER",
  //   title: "Framer: The Complete Guide ─ Smart Components",
  //   key: "framer-the-complete-guide",
  //   excerpt:
  //     "Master Framer from the basics to advanced and go all the way to creating your own prototypes and interactive design systems.",
  //   courseParts: [
  //     {
  //       label: "Framer Overview",
  //       key: "framer-overview",
  //     },
  //     {
  //       label: "Interaction Basics (Smart Component)",
  //       key: "smart-component-basics",
  //     },
  //     {
  //       label: "Interaction Advanced (Smart Component)",
  //       key: "smart-component-advanced",
  //     },
  //   ],
  //   illust: "",
  //   show: ["course-cards", "post-layout", "learn"],
  // },
  {
    label: "BEGINNER",
    title: "Modern HTML CSS for Beginners",
    key: "modern-html-css",
    excerpt:
      "Without any code backgrounds, Learn essential and powerful modern HTML & CSS. Then let's make an Instagram website clone.",
    courseParts: [
      {
        label: "HTML Basics",
        key: "html-basics",
      },
      {
        label: "CSS Basics",
        key: "css-basics",
      },
      {
        label: "Instagram Clone",
        key: "instagram-clone",
      },
    ],
    illust: "",
    show: ["course-cards", "post-layout", "learn"],
  },
  {
    label: "INTERMEDIATE",
    title: "React JS for Beginners",
    key: "react",
    excerpt:
      "Let's make a reuseable React component system from the HTML & CSS codes you write before. Then add Dark Theme to the Instagram website.",
    courseParts: [
      {
        label: "JavaScript Basics",
        key: "javascript-basics",
      },
      {
        label: "React Component",
        key: "react-component",
      },
      {
        label: "CSS-in-JS",
        key: "css-in-js",
      },
      {
        label: "My Button Component",
        key: "my-button-component",
      },
      {
        label: "Instagram - CSS to React Components",
        key: "instagram-components",
      },
      {
        label: "Theming With CSS Variables",
        key: "theming-with-css-variables",
      },
    ],
    illust: "",
    show: ["course-cards", "post-layout", "learn"],
  },
  // {
  //   label: "INTERMEDIATE",
  //   title: "Design systems and Framer",
  //   key: "design-systems-and-framer",
  //   excerpt: "Turn your React components into manageable systems using Framer",
  //   courseParts: [
  //     {
  //       label: "React Components on Framer",
  //       key: "react-components-on-framer",
  //     },
  //     {
  //       label: "Instagram Design System",
  //       key: "instagram-design-system",
  //     },
  //   ],
  //   illust: "",
  //   show: ["course-cards", "post-layout", "learn"],
  // },
  // {
  //   label: "INTERMEDIATE",
  //   title: "Fast Prototyping with Framer Desktop",
  //   key: "fast-prototyping",
  //   excerpt: `Let's make prototypes quickly using "Override" function in Framer Desktop.`,
  //   courseParts: [
  //     {
  //       label: "Lv.1-1: Fast Prototyping with Imperative Way",
  //       key: "1-1",
  //     },
  //     {
  //       label: "Lv.1-2: More Responses & Triggers",
  //       key: "1-2",
  //     },
  //     {
  //       label: "Lv.2: JavaScript Basics (Condition & Variables)",
  //       key: "2",
  //     },
  //     {
  //       label: "Lv.3-1: Controlling States (Override + Data)",
  //       key: "3-1",
  //     },
  //   ],
  //   illust: "",
  //   show: ["course-cards", "post-layout", "learn"],
  // },
  // {
  //   title: "Unsorted",
  //   key: "unsorted",
  //   excerpt: "",
  //   courseParts: [
  //     {
  //       label: "Toolbox",
  //       key: "toolbox",
  //     },
  //   ],
  //   illust: "HTML_CSS",
  //   label: "",
  //
  // },
]
