import axios from "axios"
import log from "loglevel"
import { getUserDocumentByEmail } from "."
import { STRIPE_SERVERLESS_API_BASE } from "../../constants"
import { UserDBT, UserT } from "../../types"

export async function fetchAndStoreUserDB({
  userDB = null,
  email,
  user,
  setUser,
}) {
  let db: UserDBT | null | undefined = userDB
  if (!userDB) db = await getUserDocumentByEmail(email)

  // add subscription data and check is member from stripe
  const userData = await checkSubscription(db)
  setUser(userData)
}

async function checkSubscription(userDB) {
  log.debug(`User/checkSubscription`)
  let userData: UserT = { ...userDB }

  // check user has free trial periods
  const trialLeft = userDB?.trial_end - Math.floor(Date.now() / 1000)
  if (trialLeft)
    userData = {
      ...userData,
      trialLeft,
    }

  // check subscription data from stripe
  if (userDB.subscription) {
    const { data: subscription } = await axios.post(
      STRIPE_SERVERLESS_API_BASE + "/subscriptions/retrieve",
      { id: userDB.subscription.id }
    )
    userData = {
      ...userData,
      subscription,
      member: ["active", "trialing"].includes(subscription.status),
    }
  }
  return userData
}
