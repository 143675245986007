import { useEffect, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useAsync } from "../use-async"
import { CANVAS_DEFAULT_FPS } from "../../constants"
import { FrameUnpacker } from "../../handlers"
import { veFramesState, veInfoState, veState } from "../../store"
import log from "loglevel"

export function useCanvasFrames({ videoFramesPath, canvasFrames, user }) {
  const [unpackProgress, setUnpackProgress] = useState(0)

  const setVe = useSetRecoilState(veState)
  const [{ videoDuration }, setVeInfo] = useRecoilState(veInfoState)
  const [{ frames }, setVeFrames] = useRecoilState(veFramesState)

  const { execute, loading, data, error } = useAsync({
    asyncFunc: async () => {
      const options = {
        urlPattern: `${videoFramesPath}/image{{id}}.jpg`,
        start: 1,
        end: canvasFrames,
        padding: 3,
        progress: value => setUnpackProgress(value),
        slowLoad: user?.settings?.slowLoad,
        videoCanvasQuality: user?.settings?.videoCanvasQuality || 1,
      }

      log.debug(
        `%cVideoScrubber/Please be patient. Downloaing ${options.end} from ${videoFramesPath} frames...`,
        `color: yellow;`
      )

      const startTime = Date.now()
      const unpacked = await FrameUnpacker.unpack(options)
      const endTime = Date.now()

      log.debug(
        `%cVideoScrubber/Took ${
          (endTime - startTime) / 1000
        } seconds for [${videoFramesPath}] frames.`,
        `color: yellow;`
      )
      return unpacked
    },
    immediate: false,
    funcParams: { data: "1" },
    initialData: [],
  })

  // unpack frames (only once)
  useEffect(() => {
    let mounted = true

    async function update() {
      if (frames.length === 0 && videoDuration > 0) execute({})
    }

    if (mounted) update()

    return () => {
      mounted = false
    }
  }, [videoDuration])

  // store datas
  useEffect(() => {
    setVeFrames({ loading, progress: unpackProgress, frames: data })
  }, [loading, unpackProgress, data])

  // clean ups
  useEffect(() => {
    return () => {
      log.debug(`flow/UNMOUNT! - init veFrames, veInfo, currentVideoTime`)
      setVeFrames({ loading: false, progress: 0, frames: [] })
      setVeInfo({ videoDuration: 0 })
      setVe({ currentVideoTime: 0, currentProgress: 0 })
    }
  }, [])

  return [loading, unpackProgress]
}
