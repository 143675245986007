import * as System from "@harborschool/lighthouse"
import { useHover, useTheme } from "@harborschool/lighthouse"
import { motion, AnimatePresence } from "framer-motion"
import { useAtom } from "jotai"
import log from "loglevel"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { useRecoilValue } from "recoil"
import { CANVAS_RESOLUTION, VIDEO_RATIO } from "../../../../constants"
import { CanvasFrameScrubber } from "../../../../handlers"
import { userState, veFramesState, veSectionState, veState } from "../../../../store"
import { SectionDetailContext } from "../../../../store/section-detail-provider"
import { isClient } from "../../../../utils"
import {
  FrameUnpackProgress,
  SectionDetailExplorerWrap,
  StepProgressText,
  StickyWrap,
} from "./styled-components"

export function SectionDetailCanvas({ sectionNumber }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const canvasRef = useRef(null)
  const [user] = useAtom(userState)
  const videoCanvasQuality = user?.settings?.videoCanvasQuality || 1

  const [observer, setObserver] = useState<any>()
  const { loading, progress, frames } = useRecoilValue(veFramesState)
  const veSection = useRecoilValue(veSectionState)
  const { currentProgress } = useRecoilValue(veState)

  const [hoverRef, isHover] = useHover({})
  const motionProps = {
    stepProgress: {
      initial: {
        opacity: 1,
      },
      animate: {
        opacity: isHover ? 0 : 1,
      },
    },
  }

  useEffect(() => {
    const canvas: any = canvasRef.current
    if (canvas && frames.length > 0) {
      const ctx = canvas.getContext("2d", { alpha: false }) // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas#turn_off_transparency
      ctx.drawImage(frames[0], 0, 0)

      const observer = CanvasFrameScrubber.create(ctx, frames)
      setObserver(observer)

      log.debug(`VideoScrubber/sectionNumber`, sectionNumber)
      log.debug(`VideoScrubber/observer`, observer)
    }
  }, [canvasRef, frames])

  useEffect(() => {
    const isActive = sectionNumber === veSection.currentSection

    if (isActive && currentProgress > 0 && observer)
      observer.next(currentProgress)
  }, [observer, currentProgress])

  return (
    <SectionDetailExplorerWrap ref={hoverRef} {...sharedProps}>
      {isClient && (
        <Sticky>
          {loading && (
            <FrameUnpackProgress {...sharedProps}>
              Loading... {progress}%
            </FrameUnpackProgress>
          )}
          <canvas
            width={CANVAS_RESOLUTION / VIDEO_RATIO}
            height={CANVAS_RESOLUTION}
            style={{
              width: "100%",
              height: "100%",
            }}
            ref={canvasRef}
          />
          {progress === 100 * videoCanvasQuality && (
            <motion.div {...motionProps.stepProgress}>
              <StepProgress />
            </motion.div>
          )}
        </Sticky>
      )}
    </SectionDetailExplorerWrap>
  )
}

function Sticky({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }

  return (
    <StickyWrap {...sharedProps}>
      {children}
      <System.Block
        backgroundColor="primary"
        height="scale300"
        position="absolute"
        bottom="0"
        overrides={{
          Block: {
            borderRadius: "1000px",
          },
        }}
      />
    </StickyWrap>
  )
}

function StepProgress() {
  const theme = useTheme()
  const ctx: any = React.useContext(SectionDetailContext) // States from Form
  const done = ctx.currentStepInfo.progress === 100

  const defaultTransition = {
    ease: theme.animation.easeInCurve,
    duration: theme.animation.timing200,
  }
  const motionProps = {
    progress: {
      initial: {
        width: "0%",
      },
      animate: {
        width: `${ctx.currentStepInfo.progress}%`,
        background: done
          ? theme.colors.backgroundSecondary
          : theme.colors.backgroundInverseSecondary,
      },
      transition: {
        ...defaultTransition,
        background: {
          ...defaultTransition,
          delay: theme.animation.timing300,
        },
      },
    },
    step: {
      initial: {
        y: -20,
      },
      animate: {
        y: 0,
      },
      exit: {
        y: -50,
      },
      transition: defaultTransition,
    },
    done: {
      initial: {
        y: 20,
      },
      animate: {
        y: 0,
      },
      exit: {
        y: 50,
      },
      transition: defaultTransition,
    },
  }

  return (
    <System.Block
      position="absolute"
      width="scale1600"
      height="scale1600"
      borderRadius="1000px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxShadow="overlay600"
      overflow="hidden"
      overrides={{
        Block: {
          right: theme.sizing.scale700,
          bottom: theme.sizing.scale700,
          [theme.mediaQuery.large]: {
            right: "initial",
            left: theme.sizing.scale700,
          },
        },
      }}
    >
      {/* Progress */}
      <System.Block
        as={motion.div}
        // backgroundColor="backgroundInverseSecondary"
        position="absolute"
        left="0px"
        height="100%"
        {...motionProps.progress}
      />
      <System.LabelMedium
        color="contentInversePrimary"
        overrides={{
          Block: { zIndex: 1 }, // floating it up above the progress.
        }}
      >
        <AnimatePresence>
          {done && (
            <StepProgressText {...motionProps.done}>👍</StepProgressText>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!done && (
            <StepProgressText {...motionProps.step}>
              Step {ctx.currentStepInfo.current + 1}
            </StepProgressText>
          )}
        </AnimatePresence>
      </System.LabelMedium>
    </System.Block>
  )
}
