import { loadFireStore } from "../../handlers"
import { CollectionReference } from "@firebase/firestore-types"

export const getUserDocumentByEmail = async email => {
  try {
    const collection: CollectionReference = await loadFireStore("user", null)
    const snapshots = await collection.where("email", "==", email).get()
    if (snapshots.size > 0) {
      const snapshot = snapshots.docs[0]
      const data = snapshot.data()

      // get progress of courses
      const progressCollection = await snapshot.ref.collection("progress")
      const progressSnapshot = await progressCollection.get()
      const progressDatas = {}
      progressSnapshot.forEach(doc => {
        progressDatas[doc.id] = { ...doc.data() }
      })

      return {
        uid: snapshot.id,
        ...data,
        progress: { ...progressDatas },
      }
    }
  } catch (error) {
    console.error("error - getUserDocumentByEmail", error)
  }
}
