import { videoScrubberGuide } from "./../../utils/utils"
import { transform } from "framer-motion"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import {
  SCROLL_FOCUS_POINT,
  veScrollRevealDelay,
  lastStepFastForwarded,
} from "../../constants"
import { veSectionState, veState } from "../../store"
import { veInfoState, veTOCState } from "../../store/store"
import log from "loglevel"
import { getScrollY, isClient } from "../../utils"

export function useVsUpdateByScroll({
  markersMap,
  contentsMap,
  $blockContent,
}) {
  const setVe = useSetRecoilState(veState)
  const setVeInfo = useSetRecoilState(veInfoState)
  const setVeSection = useSetRecoilState(veSectionState)
  const setVeTOC = useSetRecoilState(veTOCState)
  let tempCurrentSectionNum = 0 // to safely update currentSection state
  let tempShowTOC = false
  let tempAutoPlayVideo = 0 // to safely update currentSection state

  let contentMapArray, markersMapArray, sectionMap, videoDuration

  log.debug(`useVsUpdateByScroll/videoDuration`, videoDuration)

  useEffect(() => {
    let synced = false

    if (
      // sectionSummaryHeight !== 0 &&
      markersMap.sections.length &&
      contentsMap.heights.length &&
      markersMap.sections.length === contentsMap.heights.length && // do scroll tracking after other states are ready
      !contentMapArray &&
      !markersMapArray &&
      !sectionMap &&
      !videoDuration &&
      !$blockContent
    ) {
      markersMapArray = generateMarkersMapArray(markersMap)
      contentMapArray = generateContentMapArray(contentsMap)
      log.debug(`VideoScrubber/contentMapArray`, contentMapArray)
      log.debug(`VideoScrubber/markersMapArray`, markersMapArray)
      if (videoScrubberGuide) console.table([markersMapArray, contentMapArray])
      sectionMap = makeSectionMapfromMarkers(contentsMap)
      log.debug(`TableOfContents/sectionMap`, sectionMap)

      if (contentMapArray.length === markersMapArray.length) synced = true
      log.debug(
        `%cVideoScrubber/synced: ${synced}`,
        `color: ${synced ? "green" : "red"};`
      )

      // store video info
      videoDuration = markersMapArray[markersMapArray.length - 1]
      log.debug(`useVsUpdateByScroll/videoDuration`, videoDuration)
      setVeInfo({
        videoDuration,
      })
    }

    function handleScroll() {
      if (
        contentMapArray &&
        markersMapArray &&
        sectionMap &&
        videoDuration &&
        synced
      ) {
        // onReady to Explore the video
        const y = getScrollY()
        const newVideoTime: number = transform(
          y,
          contentMapArray,
          markersMapArray
        )

        // stop updates after complete seeking.
        if (newVideoTime < videoDuration) {
          // store video state
          setVe({
            currentVideoTime: newVideoTime,
            currentProgress: (newVideoTime / videoDuration) * 100,
          })
          // updateCurrentSection (animating TableOfContents)
          if (sectionMap.sections[tempCurrentSectionNum]) {
            if (
              sectionMap.sections[tempCurrentSectionNum + 1] &&
              y > sectionMap.sections[tempCurrentSectionNum + 1].start
            ) {
              //[Case 3] Section 2 Summary ~ (Top to Bottom)
              setVeSection({
                currentSection: ++tempCurrentSectionNum,
              })
            } else if (
              y < sectionMap.sections[tempCurrentSectionNum].start &&
              tempCurrentSectionNum >= 1
            ) {
              //[Case 4] Post End ~ (Bottom to Top)
              setVeSection({
                currentSection: --tempCurrentSectionNum,
              })
            } else if (y < sectionMap.sections[0].start) {
              //[Case 1] Post Summary ~ Section 1 Summary
              setVeSection({
                currentSection: -1,
              })
            } else if (
              sectionMap.sections[0].end > y &&
              y > sectionMap.sections[0].start
            ) {
              //[Case 2] Section 1 Start ~ Section 1 End
              tempCurrentSectionNum = 0
              setVeSection({
                currentSection: 0,
              })
            }

            // Handle Table of Contents
            if (!tempShowTOC && tempCurrentSectionNum === -1) {
              tempShowTOC = true
              setVeTOC({ show: true })
            } else if (tempShowTOC && newVideoTime === videoDuration) {
              tempShowTOC = false
              setVeTOC({ show: false })
            } else if (!tempShowTOC && newVideoTime < videoDuration) {
              tempShowTOC = true
              setVeTOC({ show: true })
            }

            // Handle AutoPlayVideo
            // if (
            //   sectionMap.sections[tempAutoPlayVideo + 1] &&
            //   y + sectionSummaryHeight * 0.5 >
            //     sectionMap.sections[tempAutoPlayVideo + 1].start
            // )
            //   setAutoPlayVideo(++tempAutoPlayVideo)
            // else if (
            //   y + sectionSummaryHeight * 0.5 <
            //     sectionMap.sections[tempAutoPlayVideo].start &&
            //   tempAutoPlayVideo >= 1
            // )
            //   setAutoPlayVideo(--tempAutoPlayVideo)
            // // Handle Appear of the first Video Scrubber!
            // else if (y + sectionSummaryHeight * 0.5 < sectionMap.sections[0].start)
            //   setAutoPlayVideo(-1)
            // else if (
            //   sectionMap.sections[0].end > y + sectionSummaryHeight * 0.5 &&
            //   y + sectionSummaryHeight * 0.5 > sectionMap.sections[0].start
            // ) {
            //   tempAutoPlayVideo = 0
            //   setAutoPlayVideo(0)
            // } // Handle End of Scroll
            // else if (newVideoTime === videoDuration) setAutoPlayVideo(-1)
            // else setAutoPlayVideo(tempAutoPlayVideo)
          }
        }
      }
    }

    window.addEventListener("scroll", handleScroll)

    // console.groupEnd()
    // return window.removeEventListener("scroll", handleScroll)
    return () => {
      // contentMapArray = null
      // markersMapArray = null
      // sectionMap = null
      // videoDuration = null
      window.removeEventListener("scroll", handleScroll)
    }
  }, [markersMap, contentsMap, $blockContent])
}

function makeSectionMapfromMarkers({
  postSummaryHeight,
  heights,
  sectionSummaryHeights,
  stepHeights,
}) {
  const sections = []
  const steps = []
  const sectionHeights = heights
  // make sections
  for (let i = 0; i < sectionHeights.length; i++) {
    const element = sectionHeights[i]
    const currentStart =
      (sections[i - 1] ? sections[i - 1].end : postSummaryHeight) +
      sectionSummaryHeights[i]
    sections[i] = {
      start: currentStart,
      end: currentStart + element - sectionSummaryHeights[i],
    }
  }
  const delayedSections = []
  for (let i = 0; i < sections.length; i++) {
    const element = sections[i]
    delayedSections[i] = {
      start: element.start - veScrollRevealDelay,
      end: element.end - veScrollRevealDelay,
    }
  }
  // make steps
  return { sections: delayedSections, steps }
}

function generateMarkersMapArray(markersMap) {
  let result = [0, 0] // init with heading section
  for (let i = 0; i < markersMap.sections.length; i++) {
    const section = markersMap.sections[i]
    let currentSteps = []
    for (let i = 0; i < markersMap.steps.length; i++) {
      const step = markersMap.steps[i]
      if (step.start >= section.start)
        currentSteps = unique([...currentSteps, ...Object.values(step)]) // add step points (ex - [0, 10, 19] or [19, 24, 25])
      if (step.end === section.end) break
    }
    currentSteps.unshift(section.start)
    result = [...result, ...currentSteps]
  }
  return result
}
function unique(a) {
  for (let i = 0; i < a.length; ++i)
    for (let j = i + 1; j < a.length; ++j) if (a[i] === a[j]) a.splice(j--, 1)
  return a
}

function generateContentMapArray({
  headerHeight,
  postSummaryHeight,
  sectionSummaryHeights,
  stepHeights,
}) {
  const result = [0, postSummaryHeight + headerHeight] // init with heading section
  let fastForwarded = 0

  for (let i = 0; i < stepHeights.length; i++) {
    const sectionStepHeights = stepHeights[i]

    // reset fastForwarded value. (don't proceed fastForwared value to others.)
    if (fastForwarded > 0) {
      result.push(result[result.length - 1] + fastForwarded)
      fastForwarded = 0
    } else result.push(result[result.length - 1])

    result.push(sectionSummaryHeights[i] + result[result.length - 1])

    for (let i = 0; i < sectionStepHeights.length; i++) {
      const stepHeight = sectionStepHeights[i]

      // fastForward video(canvas) at the end of sections.
      if (i === sectionStepHeights.length - 1) {
        fastForwarded = lastStepFastForwarded
        result.push(stepHeight - fastForwarded + result[result.length - 1])
      } else result.push(stepHeight + result[result.length - 1])
    }
  }
  // make focus-point delay here
  const delayedResult = []
  for (let i = 0; i < result.length; i++) {
    const element = result[i] - SCROLL_FOCUS_POINT
    delayedResult[i] = element
  }
  return delayedResult
}
