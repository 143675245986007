import * as React from "react"
import * as Illust from "../../assets/illust"
import { isClient } from "../../utils"
import { loadFirebase } from "../firebase"
import log from "loglevel"
import { NOT_ALLOWED_EMAIl_DOMAINS } from "../../constants"

export const signIn = async (values, setFormLoading, setModal, setAlert) => {
  let url = isClient && window.location.href
  if (url.substr(-1) != "/") url = url + "/" // Append a slash to it.

  const actionCodeSettings = {
    url,
    handleCodeInApp: true,
  }

  let isAllowed = true
  for (let i = 0; i < NOT_ALLOWED_EMAIl_DOMAINS.length; i++) {
    const domain = NOT_ALLOWED_EMAIl_DOMAINS[i]
    if (values.email.includes(domain)) isAllowed = false
  }

  if (isAllowed)
    try {
      setFormLoading(true)
      // firebase signIn request
      await loadFirebase().then(f =>
        f.auth().sendSignInLinkToEmail(values.email, actionCodeSettings)
      )
      log.debug(`signIn/sendSignInLinkToEmail success`)
      window.localStorage.setItem("emailForSignIn", values.email)
      setModal("")
      setAlert({
        show: true,
        kind: "positive",
        title: "Thanks",
        message: "Check your email box",
        body: <Illust.Done width={100} />,
      })
      setFormLoading(false)
    } catch (error) {
      console.error(`SiginUp/error`, error)
      setModal("")
      setAlert({
        show: true,
        kind: "negative",
        title: "error",
        message: error.message,
        body: <Illust.Hammer width={100} />,
      })
      setFormLoading(false)
    }
}
