import * as React from "react"
import { useTheme } from "@harborschool/lighthouse"
import { StepWrap, StepInnerWrap } from "./styled-components"
import * as System from "@harborschool/lighthouse"
import { sizeBg, videoScrubberGuide } from "../../../../utils"
import { Measure } from "../../../../components/measure"

export function Step({ children, active, isFirst, isLast, scrollSpeed }) {
  const theme = useTheme()
  const sharedProps = {
    $theme: theme,
    $isFirst: isFirst,
    $isLast: isLast,
    $videoScrubberGuide: videoScrubberGuide,
  }
  const motionProps = {
    animate: {
      opacity: active ? 1 : 0.3,
      y: active ? 0 : 10,
    },
    transition: {
      duration: theme.animation.timing200,
      ease: theme.animation.easeInCurve,
    },
  }
  return (
    <StepWrap {...sharedProps} $scrollSpeed={scrollSpeed}>
      {videoScrubberGuide && <Measure target="height" />}
      <StepInnerWrap {...sharedProps} {...motionProps}>
        {children}
        <System.Spacing height="scale800" backgroundColor={sizeBg} />
      </StepInnerWrap>
    </StepWrap>
  )
}

Step.defaultProps = {}
