const config = {
  apiKey: "AIzaSyD9R0UzGWrueLxLF9OSolGnAeia1Poi9wg",
  authDomain: "https://harbor.school/__/auth/handler",
  databaseURL: "https://harbor-school.firebaseio.com",
  projectId: "harbor-school",
  storageBucket: "harbor-school.appspot.com",
  messagingSenderId: "132564212187",
  appId: "1:132564212187:web:a74ea7f214350b62e9affe",
  measurementId: "G-T25RZDXTVS",
}

export default config
