import { getFrameIndex } from "../../utils"

export const CanvasFrameScrubber = (() => {
  const create = (context, frames) => {
    let currentFrame = 0

    const observer = {
      next: percentage => {
        const frameIndex = getFrameIndex({
          percentage,
          totalLength: frames.length,
        })

        if (currentFrame === frameIndex) return

        window.requestAnimationFrame(() => {
          context.drawImage(frames[frameIndex], 0, 0)
          // frames[frameIndex].src = "" // maybe unloading the image will help with memory (https://stackoverflow.com/a/24261170/4047204)
        })
      },
    }

    return observer
  }

  return {
    create: create,
  }
})()
