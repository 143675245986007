import { sendMail } from "./../send-mail/sendMail"
import { loadFireStore } from "../../handlers"
import { EMAILS } from "../../constants"

export const addNotificationQue = async ({ comment }) => {
  const firebase = await import("firebase/app")

  try {
    // add notification que to userDB
    const doc = await loadFireStore("comments", comment.pId)
    const snapshot = await doc.get()
    const targetUserData = await snapshot.data()

    if (comment.userId !== targetUserData.userId) {
      const userDoc = await loadFireStore("user", targetUserData.userId)
      const notifications = {
        notifications: firebase.firestore.FieldValue.arrayUnion({
          kind: "comment",
          ...comment,
        }),
      }
      await userDoc.update({
        ...notifications,
      })
      // notify it
      sendMail({
        subject: "New Notification",
        from: EMAILS.notify,
        emails: [targetUserData.email],
        htmlString: `<h2>New Reply by ${comment.name}</h2><p>${comment.content}</p>`,
      })
    }
  } catch (error) {
    console.error("error - addNotificationQue", error)
    alert("Error (addNotificationQue)")
  }
}
