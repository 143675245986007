export * from "./en"
export * from "./ko"

export const POINTS_OF_LEVEL = [
  0,
  2,
  4,
  8,
  16,
  32,
  64,
  100,
  150,
  200,
  250,
  300,
  380,
]
