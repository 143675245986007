import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"
import * as React from "react"
import { useState } from "react"
import { styled } from "styletron-react"
import { VIDEO_RATIO } from "../../../../constants"
import { AspectRatio, DesktopOnly } from "../../../../utils"

const Video = styled("video", ({ $theme, $kind }) => {
  return {
    position: "absolute",
    top: "0px",
    left: "0px",
    pointerEvents: "none",
  }
})

export const LoadingWrap = styled("div", ({ $theme }) => {
  return {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    color: $theme.colors.primary,
    ...$theme.typography.ParagraphMedium,
    fontStyle: "italic",
    fontWeight: 700,
  }
})

export function SectionSummaryVideo({ src }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [canPlay, setCanPlay] = useState(false)

  return (
    <AspectRatio
      ratio={1 / VIDEO_RATIO}
      style={{
        boxShadow: theme.lighting.shadow700,
      }}
    >
      {/* <LoadingWrap {...sharedProps}>
        <System.ProgressBar
          infinite
          value={30}
          overrides={{
            Wrap: {
              width: "30%",
            },
          }}
        />
      </LoadingWrap> */}
      {!canPlay && (
        <LoadingWrap {...sharedProps}>Loading Summary...</LoadingWrap>
      )}
      <Video
        width="100%"
        src={src}
        autoPlay
        loop
        muted
        playsInline
        onCanPlay={() => setCanPlay(true)}
      />
    </AspectRatio>
  )
}

SectionSummaryVideo.defaultProps = {}
