import { useEffect } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { userState, loadingState, alertState } from "../../store"
import { navigate } from "gatsby-plugin-intl-with-root"
import { useAtom } from "jotai"

export const useUserWithGuard = () => {
  const [loading] = useAtom(loadingState)
  const [user] = useAtom(userState)
  const setAlert = useSetRecoilState(alertState)

  useEffect(() => {
    if (loading === "loaded" && !user) {
      setAlert({
        show: true,
        kind: "negative",
        title: "Signed out",
        message: "See you soon!",
      })
      navigate("/")
    }
  }, [loading, user])

  return user
}
