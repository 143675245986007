import { useEffect, useState } from "react"
import { loadFireStore } from "../../handlers"

export const useCommentsList = () => {
  const [list, setList] = useState(null)

  useEffect(() => {
    async function update() {
      const adminStudentsDoc: any = await loadFireStore("admin", "students")
      const adminStudentsCommentsCollection = await adminStudentsDoc.collection(
        "comments"
      )

      adminStudentsCommentsCollection
        .orderBy("date", "desc")
        .onSnapshot(snapshot => setList(snapshot))
    }
    update()
  }, [])

  return list
}
