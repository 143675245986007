import * as React from "react"

type OnboardingQuizContextProps = {
  Provider: any
  Consumer: any
}

export const OnboardingQuizContext: OnboardingQuizContextProps = React.createContext(
  null
)
