import * as React from "react"

export function Download({ width }) {
  return (
    <svg
      width={width}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29289 10.2929C6.68342 9.90237 7.31658 9.90237 7.70711 10.2929L11 13.5858L14.2929 10.2929C14.6834 9.90237 15.3166 9.90237 15.7071 10.2929C16.0976 10.6834 16.0976 11.3166 15.7071 11.7071L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071L6.29289 11.7071C5.90237 11.3166 5.90237 10.6834 6.29289 10.2929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C11.5523 6 12 6.44772 12 7V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V7C10 6.44772 10.4477 6 11 6Z"
        fill="currentColor"
      />
    </svg>
  )
}

Download.defaultProps = {
  width: 16,
}
