import { loadFireStore } from "../../handlers"

export const deleteUser = async userId => {
  try {
    const doc: any = await loadFireStore("user", userId)

    doc
      .delete()
      .then(function () {
        console.log("Document successfully deleted!")
        alert("User Deleted")
      })
      .catch(function (error) {
        console.error("Error deleting document: ", error)
      })
  } catch (error) {
    console.error("error - updateUser", error)
    alert("Error (delete-user)")
  }
}
