import * as React from "react"
import { Grid } from "./styled-components"
import { useTheme } from "@harborschool/lighthouse"
import { SectionDetailContext } from "../../../../store/section-detail-provider"
import { useState } from "react"

export function SectionDetail({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [currentStepInfo, setCurrentStepInfo] = useState({
    current: 0,
    progress: 0,
  })

  return (
    <SectionDetailContext.Provider
      value={{ currentStepInfo, setCurrentStepInfo }}
    >
      <Grid {...sharedProps}>{children}</Grid>
    </SectionDetailContext.Provider>
  )
}

SectionDetail.defaultProps = {}
