/**
 * Original source code from https://github.com/Swizec/useDimensions
 * @example
 *   const [ref, { x, y, width, height }]: any = useDimensions()
 */
import { useState, useCallback, useLayoutEffect } from "react"
import { DimensionObject, UseDimensionsArgs, UseDimensionsHook } from "./types"

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    top: rect.x || rect.top,
    left: rect.y || rect.left,
    x: rect.x || rect.left,
    y: rect.y || rect.top,
    right: rect.right,
    bottom: rect.bottom,
  }
}

export function useDimensions({
  liveMeasure = false,
}: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState<DimensionObject>({})
  const [node, setNode] = useState(null)

  const ref = useCallback(node => {
    setNode(node)
  }, [])

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        )
      measure()

      if (liveMeasure) {
        window.addEventListener("resize", measure)
        window.addEventListener("scroll", measure)

        return () => {
          window.removeEventListener("resize", measure)
          window.removeEventListener("scroll", measure)
        }
      }
    }
  }, [node])

  return [ref, dimensions, node]
}
