import * as React from "react"

type SectionDetailContextProps = {
  Provider: any
  Consumer: any
}

export const SectionDetailContext: SectionDetailContextProps = React.createContext(
  null
)
