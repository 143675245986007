import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { loadingState } from "../../store"
import { isClient, timeStringToSeconds } from "../../utils"
import Papa from "papaparse"
import {
  SECTION_SUMMARY_CLASSNAME,
  SECTION_DETAIL_STEPS_CLASSNAME,
} from "../../constants"
import { useAtom } from "jotai"
import log from "loglevel"

export function useVsMap(ref, videoMarkersPath, $blockContent) {
  const [loading] = useAtom(loadingState)
  const [vsMap, setvsMap] = useState({
    contentsMap: {
      headerHeight: 0,
      postSummaryHeight: 0,
      heights: [],
      sectionSummaryHeights: [],
      stepHeights: [],
    },
    markersMap: { sections: [], steps: [] },
  })

  useEffect(() => {
    // set VideoScrubber states after user loaded
    if (loading === "loaded" && !$blockContent) {
      // get headerHeight
      const headerHeight =
        isClient && document.querySelector("#mock-header").clientHeight
      // get postSummaryHeight
      const postSummaryHeight =
        isClient && document.querySelector("#post-summary-block").clientHeight
      // get heights
      const sections = ref.current.children
      const heights = Array.from(sections).map(
        (child: any, id) => child.clientHeight
      )
      // get sectionSummaryHeight
      const sectionSummaryAll = ref.current.querySelectorAll(
        `.${SECTION_SUMMARY_CLASSNAME}`
      )
      const sectionSummaryHeights = Array.from(sectionSummaryAll).map(
        (child: any, id) => child.clientHeight
      )

      // get stepHeights
      const sectionDetailStepsAll = ref.current.querySelectorAll(
        `.${SECTION_DETAIL_STEPS_CLASSNAME}`
      )
      const stepHeights = Array.from(sectionDetailStepsAll).map(
        (child: any, id) => {
          const stepHeight = Array.from(child.children).map(
            (c: any, i) => c.clientHeight
          )
          return stepHeight
        }
      )
      // getMarkerData
      Papa.parse(videoMarkersPath, {
        download: true,
        complete: results => {
          const markers = results.data // array of objects
          // sync it at store
          setvsMap({
            markersMap: makeMarkersMap(markers, $blockContent),
            contentsMap: {
              headerHeight,
              postSummaryHeight,
              heights,
              sectionSummaryHeights,
              stepHeights,
            },
          })
        },
        header: true,
        skipEmptyLines: true,
      })
    }
  }, [ref, loading, $blockContent])

  return vsMap
}

function makeMarkersMap(markers, blockContent) {
  const sections = []
  const steps = []
  const sectionMarkers = markers.filter(row => row["Marker Type"] === "Chapter")
  const stepMarkers = markers
  // make sections
  for (let i = 0; i < sectionMarkers.length - 1; i++) {
    const element = sectionMarkers[i]
    const nextElement = sectionMarkers[i + 1]
    sections[i] = {
      start: timeStringToSeconds(element.In),
      end: timeStringToSeconds(nextElement.In),
    }
  }
  // make steps
  for (let i = 0; i < stepMarkers.length - 1; i++) {
    const element = stepMarkers[i]
    const nextElement = stepMarkers[i + 1]
    steps[i] = {
      start: timeStringToSeconds(element.In),
      end: timeStringToSeconds(nextElement.In),
    }
  }
  return { sections: blockContent ? [sections[0]] : sections, steps }
}
