import { useStaticQuery, graphql } from "gatsby"

export const useFirstPost = () => {
  const { mdx } = useStaticQuery(
    graphql`
      query {
        mdx(frontmatter: { order: { eq: 0 }, coursePart: { eq: "1-1" } }) {
          id
          frontmatter {
            title
            order
          }
          fields {
            originalPath
          }
        }
      }
    `
  )
  return mdx
}
