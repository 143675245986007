import { useRef, useState, useEffect } from "react"

export function useIntersection(
  options: any = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
    remainAfterPassed: false,
  }
) {
  const ref: any = useRef(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const target = ref.current
    if (target)
      addInterectionObserver({
        target,
        options,
        // @ts-ignore
        onVisible: () => setVisible(true),
        // @ts-ignore
        onHide: () => setVisible(false),
      })
  }, [ref])

  return [ref, visible]
}

function addInterectionObserver({
  target,
  options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
    remainAfterPassed: false,
  },
  onVisible = entry => (entry.target.style.background = "green"),
  onHide = entry => (entry.target.style.background = "yellow"),
}) {
  const { remainAfterPassed, ...intersctionOptions } = options
  if (target) {
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) onVisible(entry)
        else if (remainAfterPassed && entry.boundingClientRect.y > 0)
          onHide(entry)
        else if (!remainAfterPassed) onHide(entry)
      })
    }

    const myObserver = new IntersectionObserver(callback, intersctionOptions)
    myObserver.observe(target)
  }
}
