exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-user-index-tsx": () => import("./../../../src/pages/admin/user/index.tsx" /* webpackChunkName: "component---src-pages-admin-user-index-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-debug-tsx": () => import("./../../../src/pages/debug.tsx" /* webpackChunkName: "component---src-pages-debug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-index-tsx": () => import("./../../../src/pages/learn/index.tsx" /* webpackChunkName: "component---src-pages-learn-index-tsx" */),
  "component---src-pages-onboarding-quiz-index-tsx": () => import("./../../../src/pages/onboarding-quiz/index.tsx" /* webpackChunkName: "component---src-pages-onboarding-quiz-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-templates-mdx-page-layout-mdx-page-layout-tsx": () => import("./../../../src/templates/mdx-page-layout/mdx-page-layout.tsx" /* webpackChunkName: "component---src-templates-mdx-page-layout-mdx-page-layout-tsx" */),
  "component---src-templates-post-layout-post-layout-tsx": () => import("./../../../src/templates/post-layout/post-layout.tsx" /* webpackChunkName: "component---src-templates-post-layout-post-layout-tsx" */)
}

