import axios from "axios"
import * as React from "react"
import * as Illust from "../../assets/illust"
import {
  COUPON,
  DISCORD_BOT,
  EMAILS,
  STRIPE_SERVERLESS_API_BASE,
  STRIPE_SUBSCRIPTION_TRIAL_DAYS,
} from "../../constants"
import {
  addUser,
  getUserDocumentByEmail,
  getUserFirestore,
  sendMail,
  signIn,
} from "../../handlers"
import { UserDBT, UserT } from "../../types"
// import mailTemplate from '../html-emails/membership-payment'
// import { sendMail } from './sendMail'
// import { slackInvitation } from './slack-invitation'
import log from "loglevel"
import { loadFireStore } from "../firebase"
import { ONE_DAY } from "../../utils"

export const subsrcibe = async (
  user,
  values,
  membership,
  stripe,
  setFormLoading,
  setModal,
  setAlert,
  setUser,
  intl
) => {
  const email = values.email || user.email
  log.debug(`subsrcibe/user`, user)
  const coupon = COUPON[values.coupon]

  try {
    const { token } = await stripe.createToken({ name: email })

    if (token) {
      setFormLoading(true)
      if (values.email) {
        // [Case 1] Subscribe first, and Sign Up.

        let userDB: UserDBT
        userDB = await getUserDocumentByEmail(email)
        log.debug(`fix-double-db/userDB - 1`, userDB)

        if (!userDB) {
          log.debug(`fix-double-db/addUser!`)
          userDB = await addUser({ email })
        }

        await startSubscribe(userDB?.uid, email, token, membership, coupon)
        signIn({ email }, setFormLoading, setModal, setAlert)

        // prevent already member subscribe
        // const userDB: UserDBT = await getUserDocumentByEmail(email)
        // log.debug(`checkoutWithoutSignup/userDB`, userDB)
        // // const userDB: UserDBT = await getUserFirestore(user.uid)
        // if (userDB && userDB.subscription) {
        //   setModal("")
        //   setAlert({
        //     show: true,
        //     kind: "negative",
        //     title: "error",
        //     message: "Already has a Subscription, Sign In First",
        //     body: <Illust.Hammer width={100} />,
        //   })
        //   setFormLoading(false)
        // } else if (userDB.uid) {
        //   // start subscribe
        //   log.debug(`checkoutWithoutSignup/start`)
        //   log.debug(`checkoutWithoutSignup/email`, email)
        //   log.debug(`checkoutWithoutSignup/user`, user)
        //   await startSubscribe(userDB.uid, email, token, membership)
        //   signIn({ email }, setFormLoading, setModal, setAlert) // sign in-up with subscribe
        // } else {
        //   const userBasicInfo = await addUser({ email })
        //   await startSubscribe(userBasicInfo.uid, email, token, membership)
        //   signIn({ email }, setFormLoading, setModal, setAlert) // sign in-up with subscribe
        // }
      } else if (user) {
        // [Case 2] Signed in user's Subscribe

        // start subscribe & store it
        const userData: UserT = {
          ...user,
          ...(await startSubscribe(user.uid, email, token, membership, coupon)),
        }
        setUser(userData)
        setModal("")
        setAlert({
          show: true,
          kind: "positive",
          title: "Thanks",
          message: "Welcome",
          body: <Illust.Heart width={100} />,
        })
        setFormLoading(false)
      }
    } else
      throw Error(
        intl.formatMessage({
          id: `ui.error.card_type_is_not_supported`,
        })
      )
  } catch (error) {
    console.error("error - subscribe", error)
    setModal("")
    setAlert({
      show: true,
      kind: "negative",
      title: "error",
      message: error.message,
      body: <Illust.Hammer width={100} />,
    })
    setFormLoading(false)
  }
}

async function startSubscribe(userId, email, token, membership, coupon) {
  if (userId) {
    // start subscribe
    const { data: customer } = await axios
      .post(STRIPE_SERVERLESS_API_BASE + "/customers/create", {
        source: token.id,
        email,
      })
      .catch(error => {
        throw Error(error.response.data.message)
      })

    let subsriptionData: any = {
      customer: customer.id,
      plan: membership.plan,
      coupon,
    }
    // create trialing only STRIPE_SUBSCRIPTION_TRIAL_DAYS is bigger than 0 days
    const defaultTrialPeriods = ONE_DAY * STRIPE_SUBSCRIPTION_TRIAL_DAYS
    if (STRIPE_SUBSCRIPTION_TRIAL_DAYS > 0)
      subsriptionData = {
        ...subsriptionData,
        trial_end: Math.floor(Date.now() / 1000) + defaultTrialPeriods,
      }

    const { data: subscription } = await axios
      .post(
        STRIPE_SERVERLESS_API_BASE + "/subscriptions/create",
        subsriptionData
      )
      .catch(error => {
        throw Error(error.response.data.message)
      })

    if (subscription.status !== "active")
      throw Error("Subscription status is not active.")

    // update userDB
    const subscriptionData = {
      customer: customer.id,
      card: token.card,
      subscription: {
        id: subscription.id,
      },
    }
    let doc = await loadFireStore("user", userId)
    await doc.update(subscriptionData)

    await discordInvitation(email)

    return {
      ...subscriptionData,
      subscription,
      member: subscription.status === "active",
    }
  }
}

async function discordInvitation(email) {
  const res = await fetch(`${DISCORD_BOT}/create-invite`)
  const data = await res.json()

  if (data)
    sendMail({
      subject: "💌 Member Only Discord invitation",
      from: EMAILS.notify,
      emails: [email],
      htmlString: `<h2>Join Here 👉 ${data.invite.url}</h2><p>This link is <strong>vaild for only 3 hours.</strong> If you have any problems with joining, please leave a message at https://harborschool.channel.io`,
    })
}
