import * as React from "react"
import { Grid } from "./styled-components"
import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"
import {
  sizeBg,
  DesktopOnly,
  MobileOnly,
  videoScrubberGuide,
} from "../../../../utils"
import { SECTION_SUMMARY_CLASSNAME } from "../../../../constants"
import { Measure } from "../../../../components/measure"

export function SectionSummary({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme, $videoScrubberGuide: videoScrubberGuide }
  return (
    <Grid className={SECTION_SUMMARY_CLASSNAME} {...sharedProps}>
      {videoScrubberGuide && <Measure target="height" />}
      <System.Spacing height="scale4800" backgroundColor={sizeBg} />
      <System.Spacing height="scale4800" backgroundColor={sizeBg} />
      {children}
      <DesktopOnly>
        <System.Spacing height="scale4800" backgroundColor={sizeBg} />
        <System.Spacing height="scale4800" backgroundColor={sizeBg} />
      </DesktopOnly>
    </Grid>
  )
}

SectionSummary.defaultProps = {}
