import { useEffect, useState } from "react"
import { loadFireStore } from "../../handlers"

export const useListeningFireStore = (collectionKey, dbChildId) => {
  let mounted = true
  const [state, setState] = useState("")

  useEffect(() => {
    let doc, snapshot
    const fetchData = async () => {
      doc = await loadFireStore(collectionKey, dbChildId)
      snapshot = doc.onSnapshot(snapshot => mounted && setState(snapshot))
    }
    fetchData()
    return function cleanup() {
      snapshot?.unsubscribe?.()

      mounted = false
    }
  }, [dbChildId])

  return state
}
