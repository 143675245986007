import { atom } from "jotai"
import { getParameterByName, isClient } from "../utils"
import { LoadingT, UserT } from "./../types"

/**
 * User
 */
export const userState = atom<UserT>(null)

/**
 * UI
 */
export const loadingState = atom<LoadingT>("before")

export const modalState = atom((isClient && getParameterByName("modal")) || "")
export const searchState = atom(false)

export const mobileMenuState = atom(false)
