import { defaultFromEmail } from "./../../constants"
import axios from "axios"
import { SES_BASE_URL } from "../../constants"

export const sendMail = async ({
  subject,
  from = defaultFromEmail,
  emails,
  htmlString,
}) => {
  const sesResponse = await axios.post(SES_BASE_URL + "/sendEmail", {
    emailFrom: from,
    emails: emails,
    subject,
    htmlString,
  })
  return sesResponse
}
